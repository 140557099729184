<template>
  <!-- <b-card> -->
  <!-- <b-card-header><b class="h1">Shipping details</b></b-card-header> -->
  <!-- <b-card-body> -->
  <b-row>
    <!-- <b-col md="8">
          <b-form-group
            label="* International shipping:"
            label-cols-md="12"
          >
            <validation-provider
              #default="{ errors }"
              name="International shipping"
              rules="required"
            >
              <v-select
                v-model="model.international_shipping"
                label="title"
                :options="[{ title: 'Flat: same cost to all buyers' }]"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <div class="d-flex align-items-center mt-1">
              <div>
                Shipping rate table You can set the shipping rates for services
                to specific countries and regions.
              </div>
              <b-link class="ml-3">
                Create
              </b-link>
            </div>
          </b-form-group>
        </b-col> -->
    <!-- <b-col md="8">
          <b-form-group
            label="* Ship to:"
            label-cols-md="12"
          >
            <validation-provider
              #default="{ errors }"
              name="Ship to"
              rules="required"
            >
              <v-select
                v-model="model.ship_to"
                label="title"
                :options="[{ title: 'Worldwide' }]"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col> -->
    <!-- <b-col md="8">
          <b-form-group
            label="* Service:"
            label-cols-md="12"
          >
            <validation-provider
              #default="{ errors }"
              name="Service"
              rules="required"
            >
              <v-select
                v-model="model.service"
                label="title"
                :options="[{ title: 'Ups worldwide express' }]"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <b-link class="mt-1 d-block">
              Offer another service
            </b-link>
          </b-form-group>
        </b-col> -->
    <!-- <b-col md="4">
          <b-form-group
            label="* Cost:"
            label-cols-md="12"
          >
            <validation-provider
              #default="{ errors }"
              name="Cost"
              rules="required"
            >
              <b-input-group
                prepend="$ | "
                class="input-group-merge"
              >
                <b-form-input
                  v-model="model.cost"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  placeholder=""
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <b-link class="mt-1 d-block">
              Calculate Shipping
            </b-link>
          </b-form-group>
        </b-col> -->
    <!-- <b-form-group
            label="Package type:"
            label-cols-md="12"
          >
            <v-select
              v-model="model.package_type"
              label="title"
              :options="[{ title: 'Package' }]"
            />
          </b-form-group> -->

    <b-col md="12">
      <div class="title-block">
        <span>*</span>
        <div class="title">
          Khối lượng kiện hàng sau khi đóng gói (đơn vị: kilogram). Vd: nếu gói hàng cân nặng 200 gram thì vui lòng điền 0.2 vào ô bên dưới
        </div>
      </div>
    </b-col>
    <b-col md="4">
      <b-form-group>
        <validation-provider
          #default="{ errors }"
          name="Weight"
          rules="required"
        >
          <b-input-group
            class="input-group-merge"
          >
            <b-form-input
              v-model="value.weight"
              type="number"
              :state="errors.length > 0 ? false : null"
              placeholder="Khối lượng (kg)"
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="12">
      <div class="title-block">
        <span>*</span>
        <div class="title">
          Kích thước hàng (cm)
        </div>
      </div>
    </b-col>
    <b-col md="4">
      <b-form-group>
        <validation-provider
          #default="{ errors }"
          name="Width"
          rules="required"
        >
          <b-input-group
            class="input-group-merge"
          >
            <b-form-input
              v-model="value.width"
              type="number"
              :state="errors.length > 0 ? false : null"
              placeholder="Chiều dài (cm)"
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <div class="x-icon">
        X
      </div>
    </b-col>
    <b-col md="4">
      <b-form-group>
        <validation-provider
          #default="{ errors }"
          name="Length"
          rules="required"
        >
          <b-input-group
            class="input-group-merge"
          >
            <b-form-input
              v-model="value.length"
              type="number"
              :state="errors.length > 0 ? false : null"
              placeholder="Chiều rộng (cm)"
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <div class="x-icon">
        X
      </div>
    </b-col>
    <b-col md="4">
      <b-form-group>
        <validation-provider
          #default="{ errors }"
          name="Height"
          rules="required"
        >
          <b-input-group
            class="input-group-merge"
          >
            <b-form-input
              v-model="value.height"
              :state="errors.length > 0 ? false : null"
              type="number"
              placeholder="Chiều cao (cm)"
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  <!-- <b-col md="8">
          <b-form-group
            label="* Item location:"
            label-cols-md="12"
          >
            <div class="d-flex align-items-center">
              <div>HCMC, Vietnam</div>
              <b-link class="ml-3">
                Change
              </b-link>
            </div>
          </b-form-group>
        </b-col> -->
  </b-row>
  <!-- </b-card-body> -->
  <!-- </b-card> -->
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BInputGroup,
  // BCard,
  // BCardBody,
  // BCardHeader,
  // BLink,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import {
  required,
  // email
} from '@validations'

export default {
  components: {
    ValidationProvider,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    // vSelect,
    // BCard,
    // BCardHeader,
    // BCardBody,
    BInputGroup,
    // BLink,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      required,
      // email,
      // returnShippingFeePaidOptions: [
      //   { title: 'Buyer' },
      //   { title: 'Seller (Free Returns)' },
      // ],
      // returnTimesOptions: [{ title: '30 days' }, { title: '60 days' }],
    }
  },
  // methods: {
  //   onEditorChange({ html }) {
  //     this.model.product_description = html
  //     this.product_description_length = html.length
  //   },
  // },
}
</script>
<style lang="sass" scoped>
.col-md-4
  position: relative
  .x-icon
    position: absolute
    right: -5px
    top: 10px

.title-block
  display: flex
  align-items: center
  margin-bottom: 4px
  span
    color: #ff0000
    margin-right: 4px
</style>
