<template>
  <!-- eslint-disable vue/attribute-hyphenation -->
  <!-- eslint-disable vue/max-attributes-per-line -->
  <div>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent>
        <b-row>
          <b-col md="9">
            <div ref="basic-information">
              <Fieldset id="basic-information" :title="$t('Basic Information')">
                <BasicInformation
                  id="BI"
                  v-model="model"
                  :categoryIdErrorText="categoryIdErrorText"
                  :trademarkOptions="trademarkOptions"
                  :originOptions="originOptions"
                  :supplierOptions="supplierOptions"
                  @changeBrand="changeBrand"
                  @changeOrigin="changeOrigin"
                  @changeSupplier="changeSupplier"
                />
              </Fieldset>
            </div>
            <div ref="sale-information">
              <Fieldset id="sale-information" :title="$t('Sales Information')">
                <SalesInformation id="SI" v-model="model" />
              </Fieldset>
            </div>
            <div ref="media-management">
              <Fieldset id="media-management" :title="$t('Media Management')">
                <MediaManagement id="MM" v-model="model" />
              </Fieldset>
            </div>
            <div ref="shipping">
              <Fieldset id="shipping" title="Shipping Detail">
                <ShippingDetail id="SD" v-model="model" />
              </Fieldset>
            </div>
            <div ref="other-information">
              <Fieldset id="other-information" :title="$t('SEO Information')">
                <SEOInfo id="SE" v-model="model" />
              </Fieldset>
            </div>
          </b-col>
          <b-col md="3">
            <div class="position-sticky top">
              <b-card no-body>
                <b-card-body>
                  <b-nav
                    vertical
                  >
                    <b-nav-item
                      v-for="item in verticalNav" :key="item.id"
                      :active="item.id === verticalNavSelected"
                      @click="pageScrollTo(item.id)"
                    >{{ $t(item.label) }}</b-nav-item>
                  </b-nav>
                </b-card-body>
              </b-card>
              <Fieldset id="status" :title="$t('Status')">
                <b-form-group label="Tag 1" label-cols-md="12">
                  <b-form-tags
                    v-model="model.tag1"
                    input-id="tags-remove-on-delete"
                    :input-attrs="{
                      'aria-describedby': 'tags-remove-on-delete-help',
                    }"
                    :placeholder="$t('Ví dụ: Trắng, Đen, ...')"
                    remove-on-delete
                  />
                </b-form-group>
                <b-form-group label="Tag 2" label-cols-md="12">
                  <b-form-tags
                    v-model="model.tag2"
                    input-id="tags-remove-on-delete"
                    :input-attrs="{
                      'aria-describedby': 'tags-remove-on-delete-help',
                    }"
                    :placeholder="$t('Ví dụ: S, M, XL, ...')"
                    remove-on-delete
                  />
                </b-form-group>
                <b-form-group label="Tag 3" label-cols-md="12">
                  <b-form-tags
                    v-model="model.tag3"
                    input-id="tags-remove-on-delete"
                    :input-attrs="{
                      'aria-describedby': 'tags-remove-on-delete-help',
                    }"
                    :placeholder="$t('Enter new keyword separated by enter')"
                    remove-on-delete
                  />
                </b-form-group>
                <b-form-group :label="$t('Display on/off')+`:`" label-cols-md="6">
                  <b-form-checkbox
                    v-model="model.is_active"
                    checked="true"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  />
                </b-form-group>
                <b-form-group :label="$t('Warehouse')+`:`" label-cols-md="6">
                  <b-form-checkbox
                    v-model="model.is_warehouse"
                    checked="true"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  />
                </b-form-group>
                <b-form-group :label="$t('Is highlight')+`:`" label-cols-md="6">
                  <b-form-checkbox
                    v-model="model.is_highlight"
                    checked="true"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  />
                </b-form-group>
                <!-- <b-form-group :label="$t('Position')+`:`" label-cols-md="6">
                  <b-form-input
                    v-model="model.position"
                    type="number"
                    :placeholder="$t('Position')"
                  />
                </b-form-group> -->
                <!-- submit button -->
                <hr>
                <div class="text-right">
                  <b-button
                    variant="info"
                    type="submit"
                    class="ml-2"
                    @click.prevent="validationForm"
                  >
                    {{ $t('Update') }}
                  </b-button>
                </div>
              </Fieldset>
              <!-- <Fieldset v-if="connectedShopee || connectedLazada" id="saleChannel" :title="$t('Sale channels')">
                <b-dropdown
                  v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                  :text="$t('Sale channels')"
                  variant="outline-info"
                >
                  <b-dropdown-item v-if="connectedShopee">
                    <b-button
                      v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                      variant="outline-info"
                      :to="`/channel/create-shopee/${this.$route.params.id}`"
                    >
                      {{ $t('Sync to shopee') }}
                    </b-button>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="connectedLazada">
                    <b-button
                      v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                      variant="outline-info"
                      :to="`/channel/create-lazada/${this.$route.params.id}`"
                    >
                      {{ $t('Sync to lazada') }}
                    </b-button>
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item>{{ $t('Separated link') }}</b-dropdown-item>
                </b-dropdown>
              </Fieldset> -->
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <Overplay v-if="model.isShow" />
  </div>
</template>
<script>
/* eslint-disable operator-linebreak */
/* eslint-disable array-callback-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unreachable */
import VueScrollactive from 'vue-scrollactive'
import {
  BRow,
  BCol,
  BForm,
  BButton,
  BFormCheckbox,
  BFormGroup,
  // BFormInput,
  // BDropdown,
  // BDropdownDivider,
  // BDropdownItem,
  BNav,
  BCard,
  BCardBody,
  BNavItem,
  BFormTags,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import BasicInformation from '@/views/pages/product/product-edit/BasicInformation.vue'
import SalesInformation from '@/views/pages/product/product-edit/SalesInformationV2.vue'
import MediaManagement from '@/views/pages/product/product-edit/MediaManagement.vue'
// import RelatedOptions from '@/views/pages/product/product-edit/RelatedOption.vue'
import ShippingDetail from '@/views/pages/product/product-edit/ShippingDetail.vue'
import Vue from 'vue'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const isBase64 = require('is-base64')

Vue.use(VueScrollactive)

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BasicInformation,
    SalesInformation,
    MediaManagement,
    BButton,
    ValidationObserver,
    BFormCheckbox,
    BFormGroup,
    // RelatedOptions,
    // BFormInput,
    // BDropdown,
    // BDropdownDivider,
    // BDropdownItem,
    BNav,
    BCard,
    BCardBody,
    BNavItem,
    BFormTags,
    ShippingDetail,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      verticalNav: [
        {
          id: 'basic-information',
          label: 'Basic Information',
        },
        {
          id: 'sale-information',
          label: 'Sales Information',
        },
        {
          id: 'media-management',
          label: 'Media Management',
        },
        {
          id: 'shipping',
          label: 'Shipping',
        },
        {
          id: 'other-information',
          label: 'SEO Optimization',
        },
      ],
      verticalNavSelected: 'basic-information',
      connectedShopee: false,
      connectedLazada: false,
      categoryIdErrorText: null,
      model: {
        isShow: true,
        variantsAPI: [],
        slug_category_child: null,
        tag1: [],
        tag2: [],
        tag3: [],
      },
      detailDefault: {},
      trademarkOptions: [],
      originOptions: null,
      supplierOptions: null,
      brand_id: null,
      origin_id: null,
      supplier_id: null,
      short_content: [
        {
          type: 'table',
          isShow: true,
          value: [
            {
              title: null,
              content: null,
            },
          ],
        },
        {
          type: 'content',
          isShow: true,
          value: null,
        },
      ],
      storage: {},
    }
  },
  async mounted() {
    this.getStorage()
    this.loadDetail()
    this.loadSiteInfo()
  },
  methods: {
    async getStorage() {
      const res = await this.mixGet('/storages', { limit: 1000 })
      if (res.status) {
        if (res.data) {
          this.storage = res.data.items.find(x => x.is_default === 1)
        }
      }
    },
    async loadSiteInfo() {
      try {
        const res = await Request.get(this.$http, `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}?user_id=${JSON.parse(localStorage.getItem('token')).id}`)
        if (res) {
          if (res.data.status) {
            // this.model = res.data.data
            if (res.data.data.shop_id_shopee !== '' && res.data.data.shop_id_shopee !== null && res.data.data.shop_id_shopee !== undefined) {
              this.connectedShopee = true
            } else {
              this.connectedShopee = false
            }
            if (res.data.data.access_token_lazada !== '' && res.data.data.access_token_lazada !== null && res.data.data.access_token_lazada !== undefined) {
              this.connectedLazada = true
            } else {
              this.connectedLazada = false
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadCurrency() {
      let currencyOptions = []
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/languages?site_id=${
            JSON.parse(localStorage.getItem('siteID')).id
          }`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            // this.value.currency_list = res.data.data.items.map(x => x.locale)
            currencyOptions = res.data.data.items
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return currencyOptions
    },
    async loadDetail() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        this.model.isShow = true
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/product/${this.$route.params.id}?site_id=${siteId}`,
        )
        if (res.status === 200) {
          this.model.isShow = false
          if (res.data.status) {
            let item = {}
            this.detailDefault = { ...res.data.data }
            item = await this.getDataTranslation(res.data.data, false)
            // this.avatar = this.model.avatar
            this.model = {
              id: item.id,
              id_number: res.data.data.id_number,
              is_active: item.is_active,
              position: item.position,
              is_highlight: Boolean(
                res.data.data.is_highlight === 1 ? true : false,
              ),
              is_warehouse: Boolean(
                res.data.data.is_warehouse === 1 ? true : false,
              ),
              oldIsWareHouse: Boolean(
                res.data.data.is_warehouse === 1 ? true : false,
              ),
              sku: res.data.data.sku,
              is_variant: res.data.data.is_variant,
              e_commerce: res.data.data.e_commerce,
              category_id: res.data.data.category_id,
              brand_id: res.data.data.trademark_id,
              brand_slug: res.data.data.trademark_slug,
              origin_id: res.data.data.origin_id,
              origin_slug: res.data.data.origin_slug,
              supplier_id: res.data.data.supplier_id,
              supplier_slug: res.data.data.supplier_slug,
              price: res.data.data.price,
              avatar: res.data.data.avatar,
              video: res.data.data.video ? res.data.data.video : this.avatarDefault(),
              video_id: res.data.data.video_id,
              video_file: null,
              size_guide: res.data.data.size_guide ? res.data.data.size_guide : this.avatarDefault(),
              size_guide_id: res.data.data.size_guide_id,
              size_guide_file: null,
              list_thumbnail_edit: [],
              list_thumbnail: res.data.data.list_thumbnail,
              list_thumbnail_id:
                res.data.data.list_thumbnail_id !== null &&
                res.data.data.list_thumbnail_id !== undefined
                  ? res.data.data.list_thumbnail_id.split(',')
                  : null,
              list_thumbnail_path:
                res.data.data.list_thumbnail_path !== null &&
                res.data.data.list_thumbnail_path !== undefined
                  ? JSON.parse(res.data.data.list_thumbnail_path)
                  : null,
              slug: res.data.data.slug,
              parent_warehouse: res.data.data.parent_warehouse,
              category_name: null,
              tags: JSON.parse(res.data.data.tags),
              created_at: item.created_at,
              ware_house: [],
              ware_house_old: [],
              variantsTable: [],
              variantsAPI: [],
              variantsAPIOld: [],
              avatar_variants: [],
              name: item.name,
              short_content:
                item.short_content !== null && item.short_content !== undefined
                  ? JSON.parse(item.short_content)
                  : this.short_content,
              content: item.content,
              title_page: item.title_page,
              meta_keyword: item.meta_keyword,
              meta_description: item.meta_description,
              isMultiCurrency: 1,
              width: item.width,
              length: item.length,
              weight: item.weight,
              height: item.height,
              inventory: 0,
              // currency_list: await this.loadCurrency(),
            }
            const tag1 = []
            const tag2 = []
            const tag3 = []
            JSON.parse(res.data.data.tags || '[]').map(x => {
              if (x.color) {
                tag1.push(x.color)
              }
              if (x.size) {
                tag2.push(x.size)
              }
              if (x.material) {
                tag3.push(x.material)
              }
            })
            this.model.tag1 = tag1
            this.model.tag2 = tag2
            this.model.tag3 = tag3
            res.data.data.warehouse.map(x => {
              const vari = JSON.parse(x.name_attribute || '[]')
              this.model.variantsAPI.push({
                name_bt1: vari[0] ? vari[0].value : null,
                name_bt2: vari[1] ? vari[1].value : null,
                name_bt3: vari[2] ? vari[2].value : null,
                tempkey: `${vari[0] ? vari[0].value : ''}${vari[1] ? `&${vari[1].value}` : ''}${vari[2] ? `&${vari[2].value}` : ''}`,
                variants: JSON.parse(x.name_attribute || '[]'),
                id_number: null,
                type: 2,
                avatar_id_old: 0,
                avatar_id: x.avatar_id,
                avatar: x.avatar,
                path: x.path,

                id: x.id,
                price: x.price,
                quantity: x.quantity,
                sku: x.sku,
                barcode: x.barcode,
                cost_price: x.cost_price,
                is_active: Boolean(
                  x.is_active === 1 ? true : false,
                ),
              })
            })
            this.model.variantsAPIOld = JSON.parse(JSON.stringify(this.model.variantsAPI))
            // this.model.variant = [
            //   {
            //     id: 1,
            //     name: 'Màu',
            //     children: ['Xanh', 'Tím'],
            //   },
            // ]
            // console.log(this.model)
            // const trademarkListTemp = await this.loadTrademarkList()
            // this.trademarkOptions = []
            // this.trademarkOptions.push({
            //   avatar: '',
            //   created_at: '',
            //   id: item.trademark_id,
            //   is_active: 1,
            //   link: '#',
            //   name: 'Chọn thương hiệu',
            //   position: 1,
            //   slug: 'chon-thuong-hieu',
            //   translation: null,
            // })
            // trademarkListTemp.map(x => {
            //   this.trademarkOptions.push(x)
            // })
            this.trademarkOptions = await this.loadTrademarkList()
            this.originOptions = await this.loadOriginList()
            this.supplierOptions = await this.loadSupplierList()

            if (
              this.model.list_thumbnail &&
              this.model.list_thumbnail.length > 0
            ) {
              this.model.list_thumbnail.map(async (x, idx) => {
                const i = {
                  id: this.model.list_thumbnail_id[idx],
                  full_link: x,
                  path: this.model.list_thumbnail_path[idx],
                }
                this.model.list_thumbnail_edit.push(i)
              })
            }
          } else {
            this.model.isShow = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.model.isShow = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (this.model.category_id === null) {
          this.categoryIdErrorText = 'The Category field is required'
          this.isShow = false
        }
        if (this.model.is_variant && this.model.variants.length === 0) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: 'Sales Information: Please set the variants.',
            },
          })
          return
        }
        if (success) {
          try {
            const translation = {
              ...this.detailDefault.translation,
            }
            translation[await this.getEditLanguage()] = {
              name: this.model.name,
              short_content: JSON.stringify(this.model.short_content),
              content: this.model.content,
              title_page: this.model.title_page,
              meta_description: this.model.meta_description,
              meta_keyword: JSON.stringify(this.model.meta_keyword),
            }
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              is_active: Number(this.model.is_active === true ? 1 : 0),
              is_highlight: Number(this.model.is_highlight === true ? 1 : 0),
              is_warehouse: Number(this.model.is_warehouse === true ? 1 : 0),
              position: Number(this.model.position),
              slug: this.model.slug,
              sku: this.model.sku,
              category_id: this.model.category_id,
              price: Number(this.model.price),
              weight: Number(this.model.weight),
              length: Number(this.model.length),
              width: Number(this.model.width),
              height: Number(this.model.height),
              // tags: JSON.stringify(this.model.tags),
              translation: JSON.stringify(translation),
              variants: [],
              storage_id: this.storage.id_number,
              slug_category_child: this.model.slug_category_child,
            }
            const tag = []
            if (this.model.tag1.length > 0) {
              this.model.tag1.map(x => {
                tag.push({
                  color: x,
                })
              })
            }
            if (this.model.tag2.length > 0) {
              this.model.tag2.map(x => {
                tag.push({
                  size: x,
                })
              })
            }
            if (this.model.tag3.length > 0) {
              this.model.tag3.map(x => {
                tag.push({
                  material: x,
                })
              })
            }
            params.tags = JSON.stringify(tag)
            if (this.brand_id !== null) {
              if (this.brand_id && this.brand_id.id) {
                params.trademark_id = this.brand_id.id_number
              } else {
                params.trademark_id = this.brand_id.id_number
              }
            }

            if (this.origin_id !== null) {
              if (this.origin_id && this.origin_id.id) {
                params.origin_id = this.origin_id.id_number
              } else {
                params.origin_id = this.origin_id.id_number
              }
            }

            if (this.supplier_id !== null) {
              if (this.supplier_id && this.supplier_id.id) {
                params.supplier_id = this.supplier_id.id_number
              } else {
                params.supplier_id = this.supplier_id.id_number
              }
            }
            // return
            if (
              (await this.getEditLanguage()) ===
              JSON.parse(localStorage.getItem('siteID')).language
            ) {
              // Default language = current language -> get current info
              params.name = this.model.name
              params.content = this.model.content
              params.title_page = this.model.title_page
              params.meta_description = this.model.meta_description
              params.meta_keyword = JSON.stringify(this.model.meta_keyword)
              params.short_content = JSON.stringify(
                this.model.short_content,
              )
            } else {
              //  Default language != current langue -> get default info
              params.name = this.detailDefault.name
              params.content = this.detailDefault.content
              params.title_page = this.detailDefault.title_page
              params.meta_description = this.detailDefault.meta_description
              params.meta_keyword = (this.detailDefault.meta_keyword)
              params.short_content = (this.detailDefault.short_content)
            }
            // if (this.detailDefault.short_content !== undefined) {
            //   params.short_content = JSON.stringify(
            //     this.detailDefault.short_content,
            //   )
            // }
            if (this.model.ware_house && this.model.ware_house.length > 0) {
              let warehouseAddTemp = []
              let warehouseUpdateTemp = []
              let warehouseDeleteTemp = []
              // this.model.ware_house.map(item => {
              //   const x = {
              //     first_attribute_id: item.first_attribute_id,
              //     first_attribute_name: item.first_attribute_name,
              //     first_attribute_name_type: item.first_attribute_name_type,
              //     is_active: item.is_active,
              //     is_warehouse: item.is_warehouse,
              //     name_temp: item.name_temp,
              //     price: item.price,
              //     quantity: item.quantity,
              //     second_attribute_id: item.second_attribute_id,
              //     second_attribute_name: item.second_attribute_name,
              //     second_attribute_name_type: item.second_attribute_name_type,
              //     sku: item.sku,
              //     is_default: item.is_default,
              //   }
              //   if (isBase64(item.avatar, { allowMime: true })) {
              //     x.avatar = item.avatar
              //   }
              //   // params.warehouse_add.push(JSON.stringify(x))
              // })

              // this.model.locale_list = this.model.currency_list.map(x => x.locale)

              // Add new
              warehouseAddTemp = this.model.ware_house.filter(
                this.comparer(this.model.ware_house_old, 'name_temp'),
              )
              if (warehouseAddTemp.length > 0) {
                params.warehouse_add = []
                // const priceJson = {}
                warehouseAddTemp.map(item => {
                  // const i = {
                  //   ...item,
                  // }
                  // this.model.locale_list.map(val => {
                  //   if (item[`currency_${val}_id`] !== undefined && item[`currency_${val}_adjust`] !== undefined) {
                  //     priceJson[item[`currency_${val}_id`]] = item[`currency_${val}_adjust`]
                  //   }
                  // })
                  // i.price_json = JSON.stringify(priceJson)
                  params.warehouse_add.push(JSON.stringify(item))
                })
              }

              // Delete old
              warehouseDeleteTemp = this.model.ware_house_old.filter(
                this.comparer(this.model.ware_house, 'name_temp'),
              )
              if (warehouseDeleteTemp.length > 0) {
                params.warehouse_delete = []
                warehouseDeleteTemp.map(item => {
                  params.warehouse_delete.push(item.id)
                })
              }

              const mixedWarehouse = warehouseAddTemp.concat(
                warehouseDeleteTemp,
              )

              // Update
              warehouseUpdateTemp = this.model.ware_house.filter(
                this.comparer(mixedWarehouse, 'name_temp'),
              )
              if (warehouseUpdateTemp.length > 0) {
                params.warehouse_update = []
                // const priceJson = {}
                warehouseUpdateTemp.map(item => {
                  const i = {
                    id: item.id,
                    is_warehouse: item.is_warehouse,
                    price: item.price,
                    quantity: item.quantity,
                    is_active: Number(item.is_active === true ? 1 : 0),
                    sku: item.sku,
                    is_default: item.is_default,
                  }
                  // this.model.locale_list.map(val => {
                  //   if (item[`currency_${val}_id`] !== undefined && item[`currency_${val}_adjust`] !== undefined) {
                  //     priceJson[item[`currency_${val}_id`]] = item[`currency_${val}_adjust`]
                  //   }
                  // })
                  // i.price_json = JSON.stringify(priceJson)
                  if (this.model.is_warehouse) {
                    i.quantity = item.quantity
                  } else {
                    i.quantity = 0
                  }
                  if (isBase64(item.avatar, { allowMime: true })) {
                    i.avatar = item.avatar
                  }
                  if (item.sku_error) {
                    i.sku_error = item.sku_error
                  }
                  if (item.price_error) {
                    i.price_error = item.price_error
                  }
                  params.warehouse_update.push(JSON.stringify(i))
                })
              }
            }
            if (isBase64(this.model.avatar, { allowMime: true })) {
              params.avatar = this.model.avatar
            }
            if (
              // eslint-disable-next-line operator-linebreak
              this.model.video_file &&
              this.model.video_file !== null
            ) {
              const formData = new FormData()
              formData.append('site_id', JSON.parse(localStorage.getItem('siteID')).id)
              formData.append('type_table', 'prod')
              formData.append('type', 'desktop')
              formData.append('avatar', this.model.video_file)
              formData.append('table_field', 'video')
              // const thumbnail = {
              //   site_id: JSON.parse(localStorage.getItem('siteID')).id,
              //   type_table: 'prod',
              //   type: 'desktop',
              //   avatar: this.model.video_file,
              //   table_field: 'video',
              // }
              const resIMG = await Request.post(
                this.$http,
                `${process.env.VUE_APP_API_IMG_URL}/images_only`,
                formData,
              )
              if (resIMG.status === 200) {
                params.video = resIMG.data.data.path
                params.video_id = resIMG.data.data.id
              }
            }
            if (
              // eslint-disable-next-line operator-linebreak
              this.model.size_guide_file &&
              this.model.size_guide_file !== null
            ) {
              const formData = new FormData()
              formData.append('site_id', JSON.parse(localStorage.getItem('siteID')).id)
              formData.append('type_table', 'prod')
              formData.append('type', 'desktop')
              formData.append('avatar', this.model.size_guide_file)
              formData.append('table_field', 'size_guide')
              // const thumbnail = {
              //   site_id: JSON.parse(localStorage.getItem('siteID')).id,
              //   type_table: 'prod',
              //   type: 'desktop',
              //   avatar: this.model.video_file,
              //   table_field: 'video',
              // }
              const resIMG = await Request.post(
                this.$http,
                `${process.env.VUE_APP_API_IMG_URL}/images_only`,
                formData,
              )
              if (resIMG.status === 200) {
                params.size_guide = resIMG.data.data.path
                params.size_guide_id = resIMG.data.data.id
              }
            }
            // Bien the v2
            if (this.model.variants && this.model.variants.length > 0) {
              // lay data bi xoa
              let variantDeleteTemp = []
              const tempDelete = []
              variantDeleteTemp = this.model.variantsAPIOld.filter(
                this.comparer(this.model.variants, 'tempkey'),
              )
              if (variantDeleteTemp.length > 0) {
                params.variant_delete = []
                variantDeleteTemp.map(item => {
                  tempDelete.push({
                    id: item.id,
                    sku: item.sku,
                    barcode: item.barcode,
                    cost_price: item.cost_price,
                    price: item.price,
                    quantity: 0,
                    avatar_id: item.avatar_id,
                    name_attribute: JSON.stringify(item.variants),
                    is_active: item.is_active === true ? 1 : 0,
                  })
                })
                params.variant_delete = JSON.stringify(tempDelete)
              }
              // map data bien the
              const temp = []
              this.model.variants.map(item => {
                const find = this.model.avatar_variants.find(x => x.name === item.name_bt1)
                if (find) {
                  temp.push({
                    id: item.id_number !== null ? item.id_number : item.id,
                    sku: item.sku,
                    barcode: item.barcode,
                    cost_price: item.cost_price,
                    price: item.price,
                    quantity: 0,
                    avatar_id: find.avatar_id,
                    avatar: find.path ? find.path : item.path,
                    avatar_id_old: find.avatar_id_old,
                    name_attribute: JSON.stringify(item.variants),
                    is_active: item.is_active === true ? 1 : 0,
                    type: item.type,
                  })
                }
              })
              params.variants = JSON.stringify(temp)
            }
            if (
              this.model.list_thumbnail_edit &&
              this.model.list_thumbnail_edit.length > 0
            ) {
              let listThumbnailId = null
              const listThumbnail = []
              let index = 0
              this.model.list_thumbnail_edit.map(async x => {
                if (isBase64(x.base64, { allowMime: true })) {
                  const thumbnail = {
                    site_id: JSON.parse(localStorage.getItem('siteID')).id,
                    type_table: 'prod',
                    type: 'desktop',
                    avatar: x.base64,
                    table_field: 'list_thumbnail',
                  }
                  const resIMG = await Request.post(
                    this.$http,
                    `${process.env.VUE_APP_API_IMG_URL}/images_only`,
                    thumbnail,
                  )
                  if (resIMG.status === 200) {
                    if (resIMG.data.status) {
                      const item = {
                        id: resIMG.data.data.id,
                        full_link: resIMG.data.data.image,
                        path: resIMG.data.data.path,
                      }
                      if (listThumbnailId) {
                        listThumbnailId += `,${item.id}`
                      } else {
                        listThumbnailId = item.id
                      }
                      listThumbnail.push(item.path)
                    } else {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Opps! Something wrong',
                          icon: 'AlertOctagonIcon',
                          variant: 'danger',
                          text: String(this.showError(resIMG.data.error, ',')),
                        },
                      })
                    }
                  }
                } else {
                  if (listThumbnailId) {
                    listThumbnailId += `,${x.id}`
                  } else {
                    listThumbnailId = x.id
                  }
                  listThumbnail.push(x.path)
                }
                index += 1
                if (index === this.model.list_thumbnail_edit.length) {
                  params.list_thumbnail_id = listThumbnailId
                  params.list_thumbnail = JSON.stringify(listThumbnail)
                  this.handlerUpdate(params)
                }
              })
            } else {
              this.handlerUpdate(params)
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: 'Please complete all fields',
            },
          })
        }
      })
    },
    changeBrand(data) {
      this.brand_id = data
    },
    changeOrigin(data) {
      this.origin_id = data
    },
    changeSupplier(data) {
      this.supplier_id = data
    },
    async loadTrademarkList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/trademarks?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            return await this.getDataTranslation(res.data.data.items, true)
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return false
    },
    async loadOriginList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/origins?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            return await this.getDataTranslation(res.data.data.items, true)
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return false
    },
    async loadSupplierList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/suppliers?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.supplierOptions = res.data.data.items
            return await this.getDataTranslation(res.data.data.items, true)
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return false
    },
    async handlerUpdate(params) {
      this.model.isShow = true
      if (
        params.warehouse_add !== undefined &&
        (params.warehouse_add.filter(val => JSON.parse(val).price_error)
          .length > 0 ||
          params.warehouse_add.filter(val => JSON.parse(val).sku_error).length >
            0)
      ) {
        this.model.isShow = false
        return
      }
      if (
        params.warehouse_update !== undefined &&
        (params.warehouse_update.filter(val => JSON.parse(val).price_error)
          .length > 0 ||
          params.warehouse_update.filter(val => JSON.parse(val).sku_error)
            .length > 0)
      ) {
        this.model.isShow = false
        return
      }
      const res = await Request.put(
        this.$http,
        `${process.env.VUE_APP_API_URL}/v2/product/${this.model.id_number}`,
        params,
      )
      if (res.status === 200) {
        this.model.isShow = false
        if (res.data.status) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Congratulation !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Update Product success',
            },
          })
          this.$router.push('/product/list')
        } else {
          this.model.isShow = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(this.showError(res.data.error, ',')),
            },
          })
        }
      }
    },
    pageScrollTo(target) {
      this.verticalNavSelected = target
      const targetPos = this.$refs[target].offsetTop
      window.scrollTo({
        top: targetPos,
        behavior: 'smooth',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.right-fixed-menu {
  width: 100%;
  max-width: 350px;
  right: 30px;
  a {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 14px;
    margin: 8px 0;
    position: relative;
    color: var(--secondary);
    &:before {
      content: '';
      height: 100%;
      width: 1.8px;
      background: var(--secondary);
      position: absolute;
      left: 0;
      top: 0;
    }
    &.active {
      color: var(--primary);
      font-weight: bold;
      &:before {
        width: 2px;
        background: var(--primary);
      }
    }
  }
}
.nav-link {
  &:not(:hover) {
    color: inherit;
  }
  &.active {
    color: #ff9f43;
  }
}
</style>
